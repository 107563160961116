<template>
  <div class="tabber">
    <div v-if="imgShow" class="index-mask" @click="imgShow = false">
      <img
        class="index-mask-img"
        src="../assets/img/browser.png"
        @click="imgShow = false"
      />
      <div class="index-mask-content">
        <div class="index-mask-title">{{ $t("如果点击下载无效") }}</div>
        <div class="index-mask-brief">1、{{ $t("点击右上角打开菜单栏") }}</div>
        <div class="index-mask-brief">2、{{ $t("使用其他浏览器打开") }}</div>
        <div class="index-mask-brief">3、{{ $t("在其他浏览器完成下载") }}</div>
      </div>
    </div>
    <router-view />
    <van-tabbar v-model="active" active-color="#5d74f2" @change="onChange">
      <van-tabbar-item name="/guide" icon="home-o">{{
        $t("首页")
      }}</van-tabbar-item>
      <van-tabbar-item name="/login" icon="user-circle-o">{{
        $t("登录")
      }}</van-tabbar-item>
      <van-tabbar-item name="/register" icon="add-o">{{
        $t("register")
      }}</van-tabbar-item>
      <van-tabbar-item
        v-if="isAndroid && type != 'android'"
        name="-1"
        icon="down"
        @click="onChange(-1)"
        >{{ $t("下载") }}</van-tabbar-item
      >
    </van-tabbar>
  </div>
</template>
<script>
import utils from "../utils/utils";
export default {
  name: "Tabber",
  data() {
    return {
      imgShow: false,
      appDownload: "",
      active: "/guide",
      isAndroid: /Android/.test(navigator.userAgent),
    };
  },
  mounted() {
    this.active = this.$route.path;
    this.siteConfig();
  },
  computed: {
    type() {
      return this.$store.state.type;
    },
  },
  methods: {
    onChange(name) {
      if (name != -1) {
        this.$router.push(name);
      } else {
        if (utils.isWechat()) {
          this.imgShow = true;
        } else {
          window.location.href = this.appDownload;
        }
      }
    },
    siteConfig() {
      // 获取客户服务信息
      this.$http.post("/v1/siteConfig").then((res) => {
        if (res.code == 200) {
          this.appDownload = res.data.appDownload;
        }
      });
    },
  },
};
</script>
<style scoped>
.index-mask {
  background: rgba(0, 0, 0, 0.8);
  position: fixed;
  width: 100vw;
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 99;
}
.index-mask-img {
  width: 100vw;
  height: auto;
}
.index-mask-content{
  position: absolute;
  top: 0;
  left: 0;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.index-mask-title {
  height: 80px;
  line-height: 80px;
  color: #ff9900;
  font-size: 16px;
  font-weight: bold;
}
.index-mask-brief {
  color: white;
  margin-bottom: 40px;
}
</style>
